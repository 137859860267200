import React from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import SEO from "../components/seo"
import Layout from "../components/layout"
import LoginForm from '../components/formLogin'
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../styledComponents/section"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const Login = ({ location }) => {
  const identity = useIdentityContext()
  const navigateTarget = '/my-account'

  return (
    <>
    <Layout location={location}>
      <SEO 
        title="Energy Healing &amp; Chakra Balancing: Login" 
        description="Login to get access to the meditations programs and audios."
        url=""
      />
      <Section>
          <Heading level={1} align="center">
            Log in
          </Heading>
          <SectionHeaderLedeDiv><p>To access your account please log in.</p></SectionHeaderLedeDiv>
          {identity.provisionalUser
              ? <EmailConfirmation />
              : <LoginForm navigateTarget={navigateTarget} />
            }
        </Section>
            
    </Layout>
    </>
  )
}

const EmailConfirmation = () => {
  return (
    <>
      <p className="px-4 mt-8 text-xl text-gray-700 sm:mt-8">
        You're almost there!
      </p>
      <p className="px-4 mt-8 text-xl text-gray-700 sm:mt-8">
        Please confirm your email and click the link
      </p>
    </>
  )
}

export default Login